import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import theme from "../../../consts/theme"
import PostCardHorizontal from "../../post-card-horizontal"
import Container from "../../container"
import SectionHead from "../../section-head"

const SC = {}

const LatestPosts = () => {
  const { allGhostPost } = useStaticQuery(graphql`
    query GhostLatestPosts {
      allGhostPost(limit: 6, sort: { order: DESC, fields: published_at }) {
        edges {
          node {
            id
            title
            excerpt
            feature_image
            slug
            tags {
              name
              slug
            }
          }
        }
      }
    }
  `)

  return (
    <SC.Container containerWidth={theme.sizes.MEDIUM_CONTAINER}>
      <SectionHead title="Últimos artigos" />
      <SC.PostList>
        {allGhostPost.edges.map(post => (
          <SC.PostCardHorizontal key={post.node.id} post={post.node} />
        ))}
      </SC.PostList>
      <SC.NextPosts to="/artigos/p/2/">
        <span>Próximo</span>
        <FontAwesomeIcon icon="arrow-right" color="#000" />
      </SC.NextPosts>
    </SC.Container>
  )
}

SC.Container = styled(Container)`
  margin-top: 120px;
  margin-bottom: 60px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    margin-top: 80px;
    padding: 0 25px;
    width: 100%;
  }
`

SC.PostList = styled.section`
  margin-top: 35px;
`

SC.PostCardHorizontal = styled(PostCardHorizontal)`
  margin-top: 45px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    margin-top: 25px;
  }

  &:first-of-type {
    margin-top: 0;
  }
`

SC.NextPosts = styled(Link)`
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  text-align: center;
  display: block;
  margin-top: 50px;
  margin-bottom: 60px;

  span {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    margin-right: 15px;
    vertical-align: text-bottom;
  }
`

export default LatestPosts
