import React from "react"
import ReactDOMServer from "react-dom/server"
import EbookAd from "./components/ebook-ad"
import theme from "./consts/theme"
import EbookAutodidatismo from "./images/ebooks/autodidatismo-para-iniciantes.jpg"
import EbookEstudosProdutivos from "./images/ebooks/como-tornar-estudos-produtivos.jpg"
import EbookDicasMelhorarIntelecto from "./images/ebooks/dicas-melhorar-intelecto.jpg"
import EbookMapaMetaAprendizado from "./images/ebooks/mapa-meta-aprendizado.jpg"
import EbookOrganizeEstudeMelhor from "./images/ebooks/organize-estude-melhor.jpg"
import EbookTecnicaFeynman from "./images/ebooks/tecnica-feynman.jpg"

export const getCategoryColor = category => {
  switch (category.toLowerCase()) {
    case "aprendizagem":
      return theme.colors.APRENDIZAGEM
    case "estudos":
      return theme.colors.ESTUDOS
    case "soft skills":
      return theme.colors.SOFT_SKILLS
    case "comportamento":
      return theme.colors.COMPORTAMENTO
    default:
      return theme.colors.PRIMARY_PURPLE
  }
}

export const getEbooks = (title = null) => {
  const ebooks = [
    {
      title: "Autodidatismo para iniciantes",
      description:
        "Conheça os 5 passos para se tornar autodidata, mudando hábitos e desenvolvendo habilidades complementares. Ser autodidata é mais fácil do que você imagina e nós vamos te provar isso!",
      cover: EbookAutodidatismo,
      url: "/ebook-autodidatismo-para-iniciantes/",
      hotmartUrl: "https://pay.hotmart.com/H83088536H",
      mailchimpTag: "eBook - Autodidatismo",
    },
    {
      title: "Como tornar seus estudos mais produtivos",
      description:
        "Aprenda mais, estudando menos! Descubra como aumentar a qualidade dos seus estudos e melhorar a sua produtividade de maneira simples e eficiente.",
      cover: EbookEstudosProdutivos,
      url: "/ebook-estudos-mais-produtivos/",
      hotmartUrl: "https://pay.hotmart.com/R83090192P",
      mailchimpTag: "eBook - Produtividade",
    },
    {
      title: "Organize & Estude melhor",
      description:
        "Sem fórmulas prontas ou dicas rasas, descubra como criar um método de organização próprio e definitivo para melhorar seus estudos imediatamente.",
      cover: EbookOrganizeEstudeMelhor,
      url: "/ebook-organize-estude-melhor/",
      hotmartUrl: "https://pay.hotmart.com/N83090377X",
      mailchimpTag: "eBook - Organização",
    },
    {
      title: "Mapa do Meta-aprendizado",
      description:
        "Confira o passo-a-passo para criar seu mapa do meta-aprendizado e aprimorar o seu processo de aprendizagem. Com o mapa do meta-aprendizado, aprender qualquer coisa fica fácil!",
      cover: EbookMapaMetaAprendizado,
      url: "/ebook-mapa-meta-aprendizado/",
      hotmartUrl: "https://pay.hotmart.com/W83090621Y",
      mailchimpTag: "eBook - Mapa do Meta-aprendizado",
    },
    {
      title: "Técnica Feynman",
      description:
        "Conheça a Técnica Feynman e aprenda como aprender DE VERDADE tudo o que você quiser. Nada de mágica ou fórmula pronta: alcance o aprendizado profundo em 4 passos!",
      cover: EbookTecnicaFeynman,
      url: "/ebook-tecnica-feynman/",
      hotmartUrl: "https://pay.hotmart.com/V83090820K",
      mailchimpTag: "eBook - Técnica Feynman",
    },
  ]

  return title ? ebooks.find(ebook => ebook.title === title) : ebooks
}

export const replaceShortcodes = postHtml => {
  getShortcodes().forEach(shortcode => {
    postHtml = postHtml
      .split(shortcode.shortcode)
      .join(getShortcodeHtml(shortcode.ebookTitle))
  })
  return postHtml
}

const getShortcodeHtml = ebookTitle =>
  ReactDOMServer.renderToString(<EbookAd ebookTitle={ebookTitle} />)

const getShortcodes = () =>
  getEbooks().map(ebook => ({
    shortcode: `[shortcode_${ebook.url.replace(/\//g, "").replace(/-/g, "_")}]`,
    ebookTitle: ebook.title,
  }))
