import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const SC = {}

const Badge = ({ text, backgroundColor, textColor, className }) => (
  <SC.Badge
    backgroundColor={backgroundColor}
    textColor={textColor}
    className={className}
  >
    <span>{text}</span>
  </SC.Badge>
)

SC.Badge = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.sizes.DEFAULT_BORDER_RADIUS};

  span {
    color: ${({ textColor }) => textColor};
    font-size: 13px;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fonts.NUNITO};
    text-transform: uppercase;
    letter-spacing: 0.5px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
      font-size: 11px;
    }
  }
`

Badge.propTypes = {
  text: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
}

Badge.defaultProps = {
  text: "",
  backgroundColor: "#fff",
  textColor: "#000",
}

export default Badge
