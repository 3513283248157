import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Container from "../components/container"

const SC = {}

const SectionHead = ({ title, controls }) => (
  <SC.SectionHead
    isFlex
    flexDirection="row"
    alignItems="flex-start"
    justifyContent="space-between"
  >
    <SC.Title>{title}</SC.Title>
    {controls}
  </SC.SectionHead>
)

SC.SectionHead = styled(Container)`
  border-bottom: 2px solid ${({ theme }) => theme.colors.GREY_2};
  width: 100%;
  padding-bottom: 10px;
`

SC.Title = styled.h2`
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.GREY_1};
  font-family: ${({ theme }) => theme.fonts.NUNITO};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    font-size: 22px;
    text-align: center;
    display: block;
  }
`

SectionHead.propTypes = {
  title: PropTypes.string,
}

SectionHead.defaultProps = {
  title: "",
}

export default SectionHead
