import React from "react"
import styled, { ThemeProvider } from "styled-components"
import PropTypes from "prop-types"
import { getEbooks } from "../utils"
import theme from "../consts/theme"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"

const SC = {}

const EbookAd = ({ ebookTitle }) => {
  const ebook = getEbooks(ebookTitle)

  return (
    <ThemeProvider theme={theme}>
      <SC.Wrapper>
        <SC.LeftWrapper>
          <SC.Label>Confira nosso eBook:</SC.Label>
          <SC.Title>{ebook.title}</SC.Title>
          <SC.Description>{ebook.description}</SC.Description>
        </SC.LeftWrapper>
        <SC.RightWrapper>
          <SC.Button target="_blank" to={ebook.hotmartUrl}>
            <span>ACESSAR</span>
            <FontAwesomeIcon icon="download" color="#000" />
          </SC.Button>
        </SC.RightWrapper>
        <SC.EbookCover alt={ebook.title} src={ebook.cover} />
      </SC.Wrapper>
    </ThemeProvider>
  )
}

SC.Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 45px auto;
  padding: 20px;
  border-radius: ${({ theme }) => theme.sizes.DEFAULT_BORDER_RADIUS};
  background-color: ${({ theme }) => theme.colors.PRIMARY_PURPLE};
  overflow: hidden;
  position: relative;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    flex-direction: column;
    text-align: center;
  }
`

SC.LeftWrapper = styled.div`
  width: 50%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
  }
`

SC.RightWrapper = styled.div`
  width: 40%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
  }
`

SC.Label = styled.span`
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  display: block;
  color: #fff;

  &:after {
    content: "";
    height: 4px;
    width: 150px;
    background-color: #fff;
    margin-top: 10px;
    display: block;
    opacity: 0.3;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
      width: 100%;
    }
  }
`

SC.Title = styled.span`
  font-size: 18px;
  display: block;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  margin-top: 10px;
  color: #fff;
`

SC.Description = styled.p`
  font-size: 14px;
  line-height: 18px;
  margin-top: 8px;
  color: #fff;
  margin: 0;
  opacity: 0.9;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    font-size: 15px;
  }
`

SC.EbookCover = styled.img`
  height: 500px;
  position: absolute;
  top: -100px;
  right: 100px;
  opacity: 0.25;
  z-index: 9;
  transform: rotate(50deg);

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    opacity: 0.12;
  }
`

SC.Button = styled(Link)`
  z-index: 99;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.GREEN_1};
  border-radius: ${({ theme }) => theme.sizes.DEFAULT_BORDER_RADIUS};
  border: none;
  outline: none;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  text-decoration-color: transparent !important;
  margin-top: 20px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
  }

  &:hover {
    opacity: 0.8;
    text-decoration-color: transparent !important;
    color: #000 !important;
    text-shadow: none !important;
  }

  span {
    margin-right: 15px;
  }
`

EbookAd.propTypes = {
  ebookTitle: PropTypes.string.isRequired,
}

export default EbookAd
