import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Container from "../../container"
import { getEbooks } from "../../../utils"

const SC = {}

const Ebooks = () => {
  const ebooks = getEbooks()

  return (
    <SC.Container>
      <SC.Title>Nossos eBooks</SC.Title>
      <SC.Description>
        Baixe agora nossos eBooks para acelerar seu aprendizado!
      </SC.Description>
      <SC.EbooksWrapper>
        {ebooks.map(ebook => (
          <SC.Ebook key={ebook.title}>
            <img src={ebook.cover} alt={`eBook ${ebook.title}`} />
            <SC.Download to={ebook.hotmartUrl}>
              <span>ACESSAR</span>
              <FontAwesomeIcon icon="download" color="#000" />
            </SC.Download>
          </SC.Ebook>
        ))}
      </SC.EbooksWrapper>
    </SC.Container>
  )
}

SC.Container = styled(Container)`
  margin-top: 100px;
  margin-bottom: 60px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    margin: 60px 0;
    padding: 0 25px;
  }
`

SC.Title = styled.h2`
  display: block;
  font-size: 26px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  font-weight: 600;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.GREY_1};
`

SC.Description = styled.h3`
  font-size: 18px;
  text-align: center;
  margin-bottom: 25px;
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.GREY_3};
`

SC.EbooksWrapper = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  margin-top: 50px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

SC.Ebook = styled.div`
  width: 200px;
  height: 320px;
  background-color: #000;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 130px;
    height: 208px;
    margin-bottom: 10px;
    margin-right: 10px;

    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }

  &:hover {
    img {
      opacity: 0.5;
      transform: scale(1.1);
    }

    a {
      bottom: 0;
    }
  }

  img {
    margin: 0;
    width: 100%;
    transition: all 0.25s ease-in-out;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
      margin-bottom: -6px;
    }
  }
`

SC.Download = styled(Link)`
  width: 100%;
  height: 50px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.GREEN_1};
  bottom: -50px;
  transition: all 0.25s ease-in-out;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    height: 40px;
  }

  span {
    color: #000;
    font-family: ${({ theme }) => theme.fonts.NUNITO};
    font-size: 15px;
    font-weight: 600;
    margin-right: 8px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
      font-size: 12px;
      margin-right: 6px;
    }
  }

  svg {
    height: 15px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
      height: 12px;
    }
  }
`

export default Ebooks
