import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LatestPosts from "../components/pages/home/latest-posts"
import Categories from "../components/pages/home/categories"
import DefaultProfileImage from "../images/favicon.png"
import { graphql } from "gatsby"
import Hero from "../components/pages/quem-somos/hero"
import Ebooks from "../components/pages/home/ebooks"

const Home = ({ data }) => (
  <Layout headerSize="big">
    <SEO
      jsonld={{
        "@context": "https://schema.org",
        "@type": "WebSite",
        publisher: {
          "@type": "Organization",
          name: "Apetrecho Digital",
          url: data.site.siteMetadata.siteUrl,
          logo: `${data.site.siteMetadata.siteUrl}${DefaultProfileImage}`,
        },
        url: data.site.siteMetadata.siteUrl,
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": data.site.siteMetadata.siteUrl,
        },
        description: data.site.siteMetadata.description,
      }}
    />
    <Hero />
    <Ebooks />
    <Categories />
    <LatestPosts />
  </Layout>
)

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        description
        siteUrl
      }
    }
  }
`

export default Home
