import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Container from "../../container"
import theme from "../../../consts/theme"
import styled from "styled-components"

const SC = {}

const Categories = () => (
  <SC.Categories>
    <Container containerWidth={theme.sizes.MEDIUM_CONTAINER}>
      <div>
        <SC.Title>Navegue por categorias</SC.Title>
        <SC.Description>
          Selecione uma categoria para ver mais conteúdos relacionados
        </SC.Description>
      </div>
    </Container>
    <SC.LinksContainer
      isFlex
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      containerWidth={theme.sizes.MEDIUM_CONTAINER}
    >
      <SC.Link categoryColor={theme.colors.APRENDIZAGEM} to="/aprendizagem/">
        <span>Aprendizagem</span>
        <FontAwesomeIcon
          icon="angle-right"
          size="lg"
          color={theme.colors.APRENDIZAGEM}
        />
      </SC.Link>
      <SC.Link categoryColor={theme.colors.ESTUDOS} to="/estudos/">
        <span>Estudos</span>
        <FontAwesomeIcon
          icon="angle-right"
          size="lg"
          color={theme.colors.ESTUDOS}
        />
      </SC.Link>
    </SC.LinksContainer>
    <SC.LinksContainer
      isFlex
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      containerWidth={theme.sizes.MEDIUM_CONTAINER}
    >
      <SC.Link categoryColor={theme.colors.SOFT_SKILLS} to="/soft-skills/">
        <span>Soft Skills</span>
        <FontAwesomeIcon
          icon="angle-right"
          size="lg"
          color={theme.colors.SOFT_SKILLS}
        />
      </SC.Link>
      <SC.Link categoryColor={theme.colors.COMPORTAMENTO} to="/comportamento/">
        <span>Comportamento</span>
        <FontAwesomeIcon
          icon="angle-right"
          size="lg"
          color={theme.colors.COMPORTAMENTO}
        />
      </SC.Link>
    </SC.LinksContainer>
  </SC.Categories>
)

SC.Categories = styled.section`
  margin-top: 60px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    padding: 0 25px;
  }
`

SC.Title = styled.h2`
  display: block;
  font-size: 26px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.GREY_1};
  margin-top: 60px;
`

SC.Description = styled.h3`
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 25px;
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  text-align: center;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.GREY_3};
`

SC.LinksContainer = styled(Container)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    flex-direction: column;
  }
`

SC.Link = styled(Link)`
  border: 2px solid ${({ categoryColor }) => categoryColor};
  width: 380px;
  height: 60px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  font-size: 22px;
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  font-weight: 600;
  color: #000;
  border-radius: ${({ theme }) => theme.sizes.DEFAULT_BORDER_RADIUS};
  transition: all 0.25s ease-in-out;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
    height: 50px;
    font-size: 18px;
    margin: 5px 0;
  }

  &:hover {
    background-color: ${({ categoryColor }) => categoryColor};
    color: #fff;

    svg {
      color: #fff;
    }
  }
`

export default Categories
