import React from "react"
import styled from "styled-components"
import Container from "../../container"

const SC = {}

const Hero = () => (
  <SC.Hero>
    <Container
      isFlex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <SC.Heading>
        Olá! Somos a <span>Apetrecho Digital.</span>
      </SC.Heading>
      <SC.Description>
        Estamos aqui para ajudar você a conquistar sua
        <br />
        <b> independência intelectual</b> e{" "}
        <b>se destacar na Era do conhecimento</b>.
      </SC.Description>
    </Container>
  </SC.Hero>
)

SC.Hero = styled.section`
  height: 320px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.PRIMARY_PURPLE};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    padding: 0 25px;
    margin-top: 50px;
    height: 280px;
  }
`

SC.Heading = styled.h1`
  color: #fff;
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  margin-top: -20px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    text-align: center;
    font-size: 32px;
    margin-top: 0;
  }

  span {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.PRIMARY_GREEN};
  }
`

SC.Description = styled.h2`
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  color: #fff;
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  margin-top: 10px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    font-size: 18px;
    line-height: 24px;

    br {
      display: none;
    }
  }
`

export default Hero
