import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Badge from "./badge"
import theme from "../consts/theme"

const SC = {}

const PostCardHorizontal = ({ post, className }) => (
  <SC.PostCard className={className}>
    <SC.ImageWrapper imgSrc={post.feature_image}>
      <SC.Badge
        text={post.tags[0].name}
        backgroundColor={theme.colors.PRIMARY_PURPLE}
        textColor="#fff"
      />
    </SC.ImageWrapper>
    <SC.TextWrapper>
      <SC.Title to={`/${post.slug}/`}>{post.title}</SC.Title>
      <SC.Description>{post.excerpt}</SC.Description>
    </SC.TextWrapper>
  </SC.PostCard>
)

SC.PostCard = styled.div`
  display: flex;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    flex-direction: column;
  }
`

SC.ImageWrapper = styled.div`
  width: 380px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("${({ imgSrc }) => imgSrc}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: ${({ theme }) => theme.sizes.DEFAULT_BORDER_RADIUS};
  position: relative;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
    height: 180px;
  }
`

SC.TextWrapper = styled.div`
  flex: 1;
  margin-left: 40px;
  padding: 10px 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    margin-left: 0;
  }
`

SC.Title = styled(Link)`
  display: block;
  font-size: 32px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.GREY_4};
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    font-size: 22px;
    line-height: 28px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.PRIMARY_PURPLE};
    text-decoration: underline;
  }
`

SC.Description = styled.p`
  display: block;
  font-size: 18px;
  line-height: 24px;
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.GREY_1};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    font-size: 16px;
    line-height: 26px;
  }
`

SC.Badge = styled(Badge)`
  position: absolute;
  right: 8px;
  top: 8px;
`

export default PostCardHorizontal
